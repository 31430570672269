import { useMemo } from 'react';
import { useIsLoggedIn } from 'bb/account';
import { useApi } from 'bb/api/browser/useApi';
import { useStableCallback } from 'bb/common/hooks/useStableCallback';

export type ReturnType<T> = Set<T> & {
    hasAnyOf: (input: T[]) => boolean;
    hasAllOf: (input: T[]) => boolean;
};

const createSet = <T>(_set: Set<T>) => {
    const enhancedSet = _set as ReturnType<T>;

    enhancedSet.hasAnyOf = (input: T[]) => input.some((s) => _set.has(s));
    enhancedSet.hasAllOf = (input: T[]) => input.every((s) => _set.has(s));

    return enhancedSet;
};

export const useOwnedEditions = <T extends string | undefined | null>(
    isbns?: T[] | T[][]
) => {
    const swrResponse = useApi('/api/my/editions/owned/all', {
        fixed: true,
        enabled: useIsLoggedIn()
    });

    const isbnStr = isbns?.join();

    const getOwnedEditions = useStableCallback(
        (_?: string, data?: string[]) => {
            if (!isbns || !data) return new Set<T>();
            const input = isbns.flat().filter(Boolean) as string[];

            const [a, b] =
                input.length > data.length ? [input, data] : [data, input];

            return a.reduce(
                (acc, item) => (b.includes(item) ? acc.add(item as T) : acc),
                new Set() as Set<T>
            );
        }
    );

    return useMemo(
        () =>
            Object.assign(swrResponse, {
                data: createSet(getOwnedEditions(isbnStr, swrResponse.data))
            }),
        [isbnStr, swrResponse, getOwnedEditions]
    );
};
