import React, { type FC } from 'react';
import Logo from 'bb/common/Logo';
import css from './bookCard.module.scss';

type BookCardImageProps = {
    source: string;
    title: string;
};

const imageWidth = 400; // 400 to sync with the apps
export const BookCardImage: FC<BookCardImageProps> = ({ source, title }) => {
    if (!source?.includes('bb-images.akamaized'))
        return <Logo variant="white" />;

    const [baseUrl] = source.split('?');

    const imgSrc = `${baseUrl}?w=${imageWidth}`;

    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
            className={css.image}
            alt=""
            src={imgSrc}
            title={title}
            loading="lazy"
        />
    );
};
