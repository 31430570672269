import React, { type PropsWithChildren, type FC } from 'react';
import classNames from 'classnames';
import css from './bookCard.module.scss';

type BookCardBadgeProps = {
    variant: 'primary' | 'secondary' | 'topList';
} & PropsWithChildren;

export const BookCardBadge: FC<BookCardBadgeProps> = ({
    variant,
    children
}: BookCardBadgeProps) => (
    <div className={classNames(css.badge, css[variant])}>{children}</div>
);
