import {
    type Book,
    type BookFormat,
    type Edition
} from 'bb/discovery/book/types';
import { getAuthors } from 'bb/discovery/book/utils';
import { type BookLightResponse } from 'bb/discovery/types';
import { useTrackingStrategy } from './useTrackingStrategy';

export type ECommerceItem = {
    item_id: string;
    item_name: string;
    index: number;
    item_brand: string;
    item_category: string[] | null;
    item_list_id: string | null;
    item_list_name: string | null;
    item_variant: string;
    price: number;
    quantity: number;
};

export type ECommerceViewItem = {
    currency: string;
    value: number;
    items: ECommerceItem[];
};

export type PurchasedEventPayload = {
    transactionId: string;
    value: number;
};

export const mapToECommerceItem = ({
    book,
    edition
}: {
    book?: Book;
    edition?: Edition;
}) => ({
    item_id: book?.id?.toString() || '',
    item_name: book?.title || '',
    index: 0,
    item_brand: getAuthors(edition?.contributors)
        .map((a) => a.displayname)
        .join(', '),
    item_category: book?.genres?.map((g) => g.genreid?.toString()) ?? null,
    item_list_id: null,
    item_list_name: null,
    item_variant: edition?.format || '',
    price: edition?.singlesale?.amount || 0,
    quantity: 1
});

const mapBookLightResponseToECommerceItem = ({
    book,
    format
}: {
    book?: BookLightResponse;
    format: string;
}) => ({
    item_id: book?.id?.toString() || '',
    item_name: book?.title || '',
    index: 0,
    item_brand: getAuthors(book?._embedded?.contributors)
        .map((a) => a.displayname)
        .join(', '),
    item_category: null,
    item_list_id: null,
    item_list_name: null,
    item_variant: format,
    price:
        format === 'audioBook'
            ? book?.audiobooksinglesale?.amount || 0
            : book?.ebooksinglesale?.amount || 0,
    quantity: 1
});

export const mapBookLightResponseToECommerceItems = ({
    book
}: {
    book?: BookLightResponse;
}) => {
    const eCommerceItems = [];
    if (book?.audiobooksinglesale && book?.audio) {
        eCommerceItems.push(
            mapBookLightResponseToECommerceItem({
                book,
                format: 'audioBook' as BookFormat
            })
        );
    }
    if (book?.ebooksinglesale && book?.ebook) {
        eCommerceItems.push(
            mapBookLightResponseToECommerceItem({
                book,
                format: 'eBook' as BookFormat
            })
        );
    }
    return eCommerceItems;
};
type Event =
    | 'select_item'
    | 'view_item'
    | 'add_to_cart'
    | 'begin_checkout'
    | 'add_payment_info'
    | 'purchase';

type Payload<T extends Event> = T extends 'begin_checkout'
    ? never
    : T extends 'view_item' | 'add_to_cart' | 'select_item'
      ? { ecommerce: ECommerceViewItem }
      : T extends 'add_payment_info'
        ? {
              payment_type: string;
          }
        : T extends 'purchase'
          ? { transaction_id: string }
          : never;

export const useECommerceTracking = () => {
    const { track } = useTrackingStrategy();

    return function tracker<T extends Event>(
        ...args: Payload<T> extends never
            ? [event: T]
            : [event: T, payload: Payload<T>]
    ) {
        const [event, payload = {}] = args;
        return track({
            event,
            ...payload,
            ...(event === 'view_item' ||
            event === 'add_to_cart' ||
            event === 'select_item'
                ? { _clear: true }
                : undefined)
        });
    };
};

export type ECommerceTracker = ReturnType<typeof useECommerceTracking>;
